
import React from "react";


export const Contact = (props) => {


  return (
    <div>
      <div id="contact">
      <div className="container">
        <div className="col-md-3 col-md-offset-1 contact-info">
        </div>
      </div>
      </div>
      <div id="footer">
      <div className="container text-center">
        <p>&copy; {new Date().getFullYear()} Diversity Media Network Inc., Chicago IL USA</p>
        <ul className="footer-menu">
        <li><a href="/privacy-policy" style={{ color: 'inherit', marginRight: '8px' }}>Privacy Policy</a></li>
        <li><a href="/terms-of-service" style={{ color: 'inherit', marginRight: '8px' }}>Terms of Service</a></li>
        <li><a href="/careers" style={{ color: 'inherit', marginRight: '8px' }}>Careers</a></li>
        </ul>
      </div>
      </div>
    </div>
    );
};
