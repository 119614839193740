import React from 'react';
import MainMenu from './interactiveMenu/mainMenu';
import './videoPlayer.css'; // Import the CSS file

const VideoPlayer = ({ data, src, type }) => {
  if (!data) {
    return <div>Loading...</div>;
  }
  return (
    <div className="video-container">
      <video 
        className="video-player" 
        loop 
        autoPlay 
        muted
      >
        <source src={src} type={type} />
        Your browser does not support the video tag.
      </video>
      <div className="main-menu-container">
        <MainMenu data={data}/> 
      </div>
    </div>
  );
};

export default VideoPlayer;
