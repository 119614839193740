import React from 'react';
import './css/privacy.css';
const Privacy = () => {
  return (
    <div className="container mt-5">
      <h1 className="mb-4">Privacy Policy</h1>
      <p className="lead">
        Welcome to Diversity Media Network. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner.
      </p>
      <h2 className="mt-4">Information We Collect</h2>
      <p>
        We may collect personal information from you such as your name, email address, and other contact details when you use our website or services.
      </p>
      <h2 className="mt-4">How We Use Your Information</h2>
      <p>
        The information we collect is used to provide and improve our services, communicate with you, and ensure the security of our website.
      </p>
      <h2 className="mt-4">Sharing Your Information</h2>
      <p>
        We do not sell, trade, or otherwise transfer your personal information to outside parties except as required by law or to protect our rights.
      </p>
      <h2 className="mt-4">Security</h2>
      <p>
        We implement a variety of security measures to maintain the safety of your personal information.
      </p>
      <h2 className="mt-4">Changes to Our Privacy Policy</h2>
      <p>
        We may update our privacy policy from time to time. Any changes will be posted on this page.
      </p>
      <h2 className="mt-4">Contact Us</h2>
      <p>
        If you have any questions about our privacy policy, please contact us at <a href="mailto:privacy@diversitymedianetwork.com">privacy@diversitymedianetwork.com</a>.
      </p>
    </div>
  );
};

export default Privacy;