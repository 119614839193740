import React from 'react';

const Careers = () => {
  return (
    <div>
      <h1>Careers at Diversity Media Network</h1>
      <p>Join our team and help us make a difference!</p>
      
      <section>
        <h2>Open Positions</h2>
        <ul>
          <li>Bilingual Journalist English-Chinese/Mandarin</li>
          <li>Bilingual Journalist English-Spanish</li>
          <li>Bilingual Journalist English-Japanese</li>
          <li>Bilingual Journalist English-Korean</li>
        </ul>
      </section>
      
      <section>
        <h2>Why Work With Us?</h2>
        <p>At Diversity Media Network, we value diversity, inclusion, and innovation. We offer competitive salaries, comprehensive benefits, and opportunities for growth and development.</p>
      </section>
      
      <section>
        <h2>How to Apply</h2>
        <p>Send your resume and cover letter to <a href="mailto:careers@diversitymedianetwork.com">careers@diversitymedianetwork.com</a>.</p>
      </section>
    </div>
  );
};

export default Careers;