import React from 'react';
import './css/terms.css';
const Terms = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Terms of Service</h1>
      <p>Last updated: October 1, 2023</p>
      
      <h2>1. Introduction</h2>
      <p>Welcome to Diversity Media Network. These terms and conditions outline the rules and regulations for the use of our website.</p>
      
      <h2>2. Intellectual Property Rights</h2>
      <p>Other than the content you own, under these Terms, Diversity Media Network and/or its licensors own all the intellectual property rights and materials contained in this Website.</p>
      
      <h2>3. Restrictions</h2>
      <p>You are specifically restricted from all of the following:</p>
      <ul>
        <li>publishing any Website material in any other media;</li>
        <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
        <li>publicly performing and/or showing any Website material;</li>
        <li>using this Website in any way that is or may be damaging to this Website;</li>
        <li>using this Website in any way that impacts user access to this Website;</li>
        <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
        <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;</li>
        <li>using this Website to engage in any advertising or marketing.</li>
      </ul>
      
      <h2>4. Your Content</h2>
      <p>In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, video text, images or other material you choose to display on this Website. By displaying Your Content, you grant Diversity Media Network a non-exclusive, worldwide irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.</p>
      
      <h2>5. No warranties</h2>
      <p>This Website is provided "as is," with all faults, and Diversity Media Network express no representations or warranties, of any kind related to this Website or the materials contained on this Website.</p>
      
      <h2>6. Limitation of liability</h2>
      <p>In no event shall Diversity Media Network, nor any of its officers, directors and employees, be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract. Diversity Media Network, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.</p>
      
      <h2>7. Indemnification</h2>
      <p>You hereby indemnify to the fullest extent Diversity Media Network from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>
      
      <h2>8. Severability</h2>
      <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
      
      <h2>9. Variation of Terms</h2>
      <p>Diversity Media Network is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.</p>
      
      <h2>10. Assignment</h2>
      <p>The Diversity Media Network is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
      
      <h2>11. Entire Agreement</h2>
      <p>These Terms constitute the entire agreement between Diversity Media Network and you in relation to your use of this Website, and supersede all prior agreements and understandings.</p>
      
      <h2>12. Governing Law & Jurisdiction</h2>
      <p>These Terms will be governed by and interpreted in accordance with the laws of the State of [Your State], and you submit to the non-exclusive jurisdiction of the state and federal courts located in [Your State] for the resolution of any disputes.</p>
    </div>
  );
};

export default Terms;