import React, { useEffect, useRef, useState } from 'react';
import MenuItem from './menuItem';
import './menu.css';
import Grid2  from '@mui/material/Grid2';
import Item from '@mui/material/Grid2'; // Assuming Item is also from @mui/material/Grid
import FadeInText from './mainContent';

const MainMenu = ({data}) => {
  const menuRef = useRef(null);
  const [fadeInText, setFadeInText] = useState('');

  useEffect(() => {
    const menuEl = menuRef.current;
    const items = [];
    [...menuEl.querySelectorAll('.menu__item')].forEach(item => items.push(new MenuItem(item)));
  }, []);

  const handleMenuItemClick = (text) => {
    setFadeInText(text);
  };

  return (
    <div className="main-menu">
      <Grid2 container spacing={1}>
        <Grid2 size={7}>
          <Item>
            <div className="fade-in-text" style={{ textAlign: 'left' }}>
              <FadeInText text={fadeInText} />
            </div>
          </Item>
        </Grid2>
        <Grid2 size={1}>
          <Item>
            <nav className="menu" ref={menuRef}>

              {data.menu.map((item, index) => (
              <div key={index} className="menu__item" onClick={() => handleMenuItemClick(item.paragraph)}>
                <svg className="menu__text" viewBox="0 0 110 20" preserveAspectRatio="xMinYMid meet">
                <defs>
                  <filter id={`goo-${index}`}>
                  <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur"></feGaussianBlur>
                  <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 1 0 1 0 0 0 0 0 15 -8" result="goo"></feColorMatrix>
                  <feComposite in="SourceGraphic" in2="goo" operator="atop"></feComposite>
                  </filter>
                </defs>
                <g>
                  <text x="0" y="15">{item.name}</text>
                  <text x="0" y="15">{item.more}</text>
                </g>
                </svg>
              </div>
              ))}
              
            </nav>`
          </Item>
        </Grid2>
      </Grid2>
      
      
    </div>
  );
};

export default MainMenu;