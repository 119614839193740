import React, { useState, useEffect } from 'react';
import './mainContent.css'; // Make sure to create this CSS file

const FadeInText = ({ text }) => {
  const [displayText, setDisplayText] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [timeoutState, setTimeoutState] = useState(null);
  useEffect(() => {
    if (timeoutState) {
      clearTimeout(timeoutState);
    }
    setIsVisible(true);
    console.log('setting text', text);
    if(text !== displayText) {
      setIsVisible(false);
    }
    setTimeout(() => {
      setDisplayText(text);
      setIsVisible(true);
    }, 500);
    const element = document.querySelector('.fade-in-text');
    if (element) {
      element.style.animation = 'none';
      element.display = 'none';
      element.style.animation = null;
      const to = setTimeout(() => {
        element.display = 'block';
        setIsVisible(false);
      }, 20000);
      setTimeoutState(to);
    }
    // eslint-disable-next-line
  }, [text]);

  return (
    <div className={`${isVisible ? 'fade-in-text' : 'fade-out-text'}`}>
      {displayText}
    </div>
  );
};

export default FadeInText;